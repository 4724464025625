import {
  AppState,
  HomePageBuenosAiresAppState,
} from '../../../initialStateRegistration';
import {
  HomePageBuenosAiresGridSection,
  HomePageBuenosAiresTopSection,
  HomePageBuenosAiresExpandableSectionsWithMore,
  SectionNames,
} from './homePageBuenosAires.initialState';

export const getHomePage = (state: AppState) => (state as HomePageBuenosAiresAppState).template;

export const getSectionArticlesByKey = (
    key:
      | HomePageBuenosAiresTopSection
      | HomePageBuenosAiresExpandableSectionsWithMore
      | HomePageBuenosAiresGridSection,
  ) => (state: AppState) => getHomePage(state)[key].articles;

export const getSectionTitleByKey = (key: SectionNames) => (state: AppState) => getHomePage(state)[key].sectionTitle;

export const getShowMorePaginationTextByKey = (key: HomePageBuenosAiresExpandableSectionsWithMore) => (state: AppState) => getHomePage(state)[key].showMorePaginationText;

export const getShowMorePaginationURLByKey = (key: HomePageBuenosAiresExpandableSectionsWithMore) => (state: AppState) => getHomePage(state)[key].showMorePaginationURL;

export const getMoreButtonByKey = (key: HomePageBuenosAiresExpandableSectionsWithMore) => (state: AppState) => getHomePage(state)[key].moreButtonText;

export const getMetadataDescription = (state: AppState) => getHomePage(state).metadataDescription;

export const getMetadataTitle = (state: AppState) => getHomePage(state).metadataTitle;

export const getMetadataImage = (state: AppState) => getHomePage(state).metadataImage;

export const getHreflangData = (state: AppState) => getHomePage(state).hreflangData;

export const getGridSectionLogo = (key: HomePageBuenosAiresGridSection) => (state: AppState) => getHomePage(state)[key].sectionTitleLogo;
